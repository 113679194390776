import { styled } from "linaria/react";

export const CampaignForm = styled.form`
  max-width: 600px;
  width: 100%;
`;

export const CampaignLabel = styled.label`
  margin-bottom: 5px;
  color: rgb(33, 37, 41);
  font-size: 16px;
  line-height: 24px;
  margin-top: 15px;
  display: block;
`;

export const CampaignSubmit = styled.button`
  background-color: rgb(0, 123, 255);
  border-radius: 4px;
  color: white;
  height: 38px;
  border: 0;
  margin-top: 25px;
  text-align: center;
  font-size: 16px;
  width: 100%;
  cursor: pointer;
  &:disabled {
    opacity: 0.6;
    cursor: not-allowed;
    pointer-events: all !important;
  }
`;

export const CampaignError = styled.div`
  color: #ff0000;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 5px;
  display: block;
`;
