import { styled } from "linaria/react";

export const SentimentContainer = styled.div`
  width: 25px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const SentimentScore = styled.div`
  padding: 6px;
  background: #fff;
  color: #000;
  font-size: 16px;
`;

export const KeywordsContainer = styled.div`
  width: 250px;
  height: 100%;
  overflow: scroll;
  text-align: -webkit-center;
`;

export const Keyword = styled.div<{ level: number }>`
  width: 80%;
  height: 30px;
  border-radius: 15px;
  background: ${(props) => (props.level === 1 ? "#f8d7da" : props.level === 2 ? "#fff3cd" : "#d1ecf1")};
  margin: 0 0 10px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
`;

export const KeywordTimestamp = styled.div`
  display: inline;
  font-size: 8px;
  right: 10px;
  position: absolute;
  bottom: 8px;
`;
