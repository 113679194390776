import { io, Socket } from "socket.io-client";
import EventEmitter from "eventemitter3";

interface IConveySocketOpts {
  correlationId: string;
  agencyId: string;
}

export class ConveySocket extends EventEmitter {
  private static instance: ConveySocket;
  static socketManager?: Socket;
  static correlationId: string;
  static agencyId: string;
  static SIP_EVENT_NAMES: Set<string> = new Set<string>();
  static LISTENED_EVENTS: string[] = [];

  static ERROR = "error";
  static CONNECT = "connect";
  static CONNECTED = "connected";
  static CONNECTING = "connecting";
  static RECONNECT = "reconnect";
  static RECONNECTING = "reconnecting";
  static RECONNECTED = "reconnected";
  static DISCONNECT = "disconnect";
  static DISCONNECTED = "disconnected";
  static CONNECT_ERR = "connect_error";
  static RECONNECT_ERR = "reconnect_error";

  static SESSION = "sessionCreated";
  static AGENCY_CONNECTED = "agencyConnected";
  static CREATE_MESSAGE = "createMessage";
  static CREATE_VIDEO_ROOM = "createVideoRoom";
  static CLOSE_VIDEO_ROOM = "closeVideoRoom";
  static ADD_RECIPIENT_GEOLOCATION = "addRecipientGeolocation";
  static ADD_RECIPIENT_CONVERSATION = "addRecipientToConversation";
  static UPDATE_CONVERSATION_RECIPIENT = "updateConversationRecipient";
  static UPDATE_VIDEO_STATUS = "updateVideoStatus";
  static CREATE_ATTACHMENT = "createAttachment";
  static UPDATE_ATTACHMENT = "updateAttachment";
  static UPDATE_MESSAGE_STATUS = "updateMessageStatus";
  static RECEIVE_TRANSCRIPTION = "receive-transcription";
  static RECEIVE_VIDEO_TRANSCRIPTION = "sdk:receive-transcription";
  static RECEIVE_SHARE_INFORMATION = "receive-share-information";
  static TRANSCRIPTION_CALLBACK = "transcriptionCallback";
  static UPDATE_RECIPIENT_LANGUAGE = "updateRecipientLanguage";
  static ADD_VIDEO_ROOM_PARTICIPANT = "addVideoRoomParticipant";
  static REMOVE_VIDEO_ROOM_PARTICIPANT = "deleteVideoRoomParticipant";
  static START_TRANSCRIPTION = "start-transcription";
  static STOP_TRANSCRIPTION = "stop-transcription";
  static ENABLE_CAMERA = "enableCamera";
  static DISABLE_CAMERA = "disableCamera";
  static ENABLE_AUDIO = "enableAudio";
  static DISABLE_AUDIO = "disableAudio";
  static TOGGLE_CAMERA = "toggleCamera";
  static SHARE_LOCATION = "shareLocation";
  static CHANGE_RECOG_LANG = "changeRecognitionLanguage";
  static VISIBILITY_CHANGE = "visibilityChange";

  // chat: onEvent messages
  static CREATE_CONVERSATION = "createConversation";
  static UPDATE_CONVERSATION = "updateConversation";
  static CLOSE_CONVERSATION = "closeConversation";
  static ASSIGN_CONVERSATION_AGENT = "assignConversationAgent";
  static UNASSIGN_CONVERSATION_AGENT = "unassignConversationAgent";
  static ADD_RECIPIENT_TO_CONVERSATION = "addRecipientToConversation";
  static REMOVE_RECIPIENT_TO_CONVERSATION = "removeRecipientFromConversation";

  static JOIN_CONVERSATION_CMD = "joinConversation";
  static SEND_TRANSCRIPTION_CMD = "send-transcription";
  static CONNECT_AGENCY_FEED_CMD = "connectAgencyFeed";
  static VIDEO_REMOTE_CONTROL_CMD = "remoteControl";
  static VIDEO_TRANSCRIPTION_CMD = "sdk:send-transcription";
  static SHARE_INFORMATION_CMD = "share-information";
  static LEAVE_VIDEO_ROOM_CMD = "leaveVideo";
  static REQUEST_START_TRANSCRIPTION_CMD = "request-start-transcription";
  static REQUEST_STOP_TRANSCRIPTION_CMD = "request-stop-transcription";

  static EVENTS = [
    ConveySocket.SESSION,
    ConveySocket.AGENCY_CONNECTED,
    ConveySocket.CREATE_MESSAGE,
    ConveySocket.ADD_RECIPIENT_GEOLOCATION,
    ConveySocket.CREATE_VIDEO_ROOM,
    ConveySocket.CLOSE_VIDEO_ROOM,
    ConveySocket.UPDATE_VIDEO_STATUS,
    ConveySocket.CREATE_ATTACHMENT,
    ConveySocket.STOP_TRANSCRIPTION,
    ConveySocket.START_TRANSCRIPTION,
    ConveySocket.UPDATE_ATTACHMENT,
    ConveySocket.RECEIVE_TRANSCRIPTION,
    ConveySocket.RECEIVE_VIDEO_TRANSCRIPTION,
    ConveySocket.RECEIVE_SHARE_INFORMATION,
    ConveySocket.TRANSCRIPTION_CALLBACK,
    ConveySocket.UPDATE_RECIPIENT_LANGUAGE,
    ConveySocket.ADD_VIDEO_ROOM_PARTICIPANT,
    ConveySocket.REMOVE_VIDEO_ROOM_PARTICIPANT,
    ConveySocket.ENABLE_CAMERA,
    ConveySocket.DISABLE_CAMERA,
    ConveySocket.ENABLE_AUDIO,
    ConveySocket.DISABLE_AUDIO,
    ConveySocket.TOGGLE_CAMERA,
    ConveySocket.SHARE_LOCATION,
    ConveySocket.CHANGE_RECOG_LANG,
    ConveySocket.VISIBILITY_CHANGE,
    ConveySocket.UPDATE_MESSAGE_STATUS,
    ConveySocket.CREATE_CONVERSATION,
    ConveySocket.UPDATE_CONVERSATION,
    ConveySocket.CLOSE_CONVERSATION,
    ConveySocket.ASSIGN_CONVERSATION_AGENT,
    ConveySocket.UNASSIGN_CONVERSATION_AGENT,
    ConveySocket.ADD_RECIPIENT_TO_CONVERSATION,
    ConveySocket.ADD_RECIPIENT_TO_CONVERSATION
  ];

  static CHAT_ON_EVENTS = [
    ConveySocket.CREATE_CONVERSATION,
    ConveySocket.UPDATE_CONVERSATION,
    ConveySocket.CLOSE_CONVERSATION,
    ConveySocket.ASSIGN_CONVERSATION_AGENT,
    ConveySocket.UNASSIGN_CONVERSATION_AGENT,
    ConveySocket.ADD_RECIPIENT_TO_CONVERSATION,
    ConveySocket.ADD_RECIPIENT_TO_CONVERSATION
  ];

  static init(accessToken: string) {
    if (!ConveySocket.socketManager) {
      ConveySocket.socketManager = io(process.env.SOCKET_HOST, {
        transports: ["websocket"],
        reconnection: true,
        reconnectionDelay: 1000,
        reconnectionDelayMax: 5000,
        reconnectionAttempts: 25,
        auth: { token: `Bearer ${accessToken}` }
      });

      ConveySocket.bindSockets();
    }

    return ConveySocket.getInstance();
  }

  static initSip({ agencyId, correlationId }: IConveySocketOpts) {
    const event = ConveySocket.getSipRoom({ agencyId, correlationId });
    ConveySocket.SIP_EVENT_NAMES.add(event);
    const sentimentEvent = ConveySocket.getSipSentiment({ agencyId, correlationId });
    ConveySocket.SIP_EVENT_NAMES.add(sentimentEvent);

    if (ConveySocket.socketManager) {
      [event, sentimentEvent].forEach((eventName) => {
        if (!ConveySocket.LISTENED_EVENTS.includes(eventName)) {
          ConveySocket.socketManager.on(eventName, (data: any) => {
            ConveySocket.getInstance().emit(eventName, data);
          });
          ConveySocket.LISTENED_EVENTS.push(eventName);
        }
      });
    }
    return ConveySocket.getInstance();
  }

  private static bindSockets() {
    if (ConveySocket.socketManager) {
      ConveySocket.socketManager.on(ConveySocket.CONNECT, () => {
        ConveySocket.onConnected();
      });
      ConveySocket.socketManager.on(ConveySocket.RECONNECT, () => {
        ConveySocket.onReconnected();
      });
      ConveySocket.socketManager.on(ConveySocket.DISCONNECT, (reason: string) => {
        const manualDisconnect = reason === "io client disconnect";

        if (manualDisconnect) {
          delete ConveySocket.socketManager;
        }
        ConveySocket.onDisconnected(manualDisconnect);
      });
      ConveySocket.socketManager.on(ConveySocket.RECONNECT_ERR, () => {
        ConveySocket.onError();
      });
      ConveySocket.socketManager.on(ConveySocket.CONNECT_ERR, () => {
        ConveySocket.onError();
      });
      ConveySocket.socketManager.on(ConveySocket.ERROR, () => {
        ConveySocket.onError();
      });
      ConveySocket.socketManager.on(ConveySocket.RECONNECTING, () => {
        ConveySocket.onReconnecting();
      });

      [...ConveySocket.EVENTS].forEach((event) => {
        if (ConveySocket.socketManager) {
          if (!ConveySocket.LISTENED_EVENTS.includes(event)) {
            ConveySocket.socketManager.on(event, (data: any) => {
              ConveySocket.getInstance().emit(event, data);
            });
            ConveySocket.LISTENED_EVENTS.push(event);
          }
        }
      });
    }
  }
  private static onConnected() {
    ConveySocket.getInstance().emit(ConveySocket.CONNECTED);
  }

  private static onReconnected() {
    ConveySocket.getInstance().emit(ConveySocket.CONNECTED);
  }

  socketEmit(event: string, ...args) {
    if (!ConveySocket.socketManager) {
      return;
    }

    ConveySocket.socketManager.emit(event, ...args);
  }

  private static onDisconnected(manualDisconnect: boolean) {
    ConveySocket.getInstance().emit(ConveySocket.DISCONNECTED, {
      manualDisconnect
    });
  }

  private static onReconnecting() {
    ConveySocket.getInstance().emit(ConveySocket.RECONNECTING);
  }

  private static onError() {
    ConveySocket.getInstance().emit(ConveySocket.ERROR);
  }

  public static joinConversation(conversationId: string) {
    ConveySocket.socketManager.emit(ConveySocket.JOIN_CONVERSATION_CMD, conversationId);
  }

  static getInstance() {
    if (!ConveySocket.instance) {
      ConveySocket.instance = new ConveySocket();
    }
    return ConveySocket.instance;
  }

  static getSipRoom({ agencyId, correlationId }: IConveySocketOpts) {
    return `sip:${agencyId}:${correlationId}`;
  }

  static getSipSentiment({ agencyId, correlationId }: IConveySocketOpts) {
    return `sipSentiment:${agencyId}:${correlationId}`;
  }

  listen(eventName: string, listener: (data?: any) => void, force?: boolean): ConveySocket {
    if (this.listeners(eventName).length > 0 && !force) {
      return this;
    } else {
      this.on(eventName, listener);
      return this;
    }
  }

  listenOff(eventName: string, listener: (data?: any) => void): ConveySocket {
    if (this.listeners(eventName).length > 0) {
      this.off(eventName, listener);
      return this;
    } else {
      return this;
    }
  }
}
