import { styled } from "linaria/react";
import { TDirection } from "@lib/utils";

export const ChatWrap = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  padding: 10px;
  background: #fff;
`;

export const Map3DContainer = styled.div`
  scale: 0.95;
  height: 550px !important;
`;

export const MapIcon = styled.div`
  position: absolute;
  top: 20px;
  left: 185px;
  width: 40px;
  height: 40px;
  z-index: 2;
  cursor: pointer;
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    width: 30px;
    height: 30px;
  }
`;

export const PinnacleIcon = styled.div`
  position: absolute;
  top: 10px;
  left: 180px;
  width: 40px;
  height: 40px;
  z-index: 2;
  cursor: pointer;
  img {
    max-width: 100%;
  }
`;
export const MapWrap = styled.div`
  position: relative;
`;
export const ChatWrapContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  position: relative;
  max-height: 100%;
  flex-grow: 1;
`;
export const ChatHeader = styled.div``;
export const ChatModuleTitle = styled.div`
  text-align: center;
  margin-top: 10px;
`;
export const ChatModuleSubtitle = styled.div`
  text-align: center;
  color: #99abb4;
  margin-top: 10px;
`;
export const ChatContainer = styled.div`
  overflow-y: scroll;
  overflow-x: hidden;
  padding: 0 5px;
  flex: 1;
`;
export const MessageNote = styled.div`
  padding: 5px;
  font-size: 14px;
  color: #555;
  text-align: left;
  cursor: pointer;
`;
export const ChatNote = styled.div<{ emphasized?: boolean }>`
  align-self: flex-end;
  padding: 5px;
  font-size: 12px;
  color: ${(props) => (props.emphasized ? "#555" : "#bbb")};
  font-style: ${(props) => (props.emphasized ? "italic" : "normal")};
  margin-bottom: ${(props) => (props.emphasized ? "0px" : "10px")};
`;
export const ChatAuthor = styled.div<{
  direction: TDirection;
}>`
  align-self: ${(props) =>
    props.direction === "outbound" ? "flex-end" : props.direction === "note" ? "center" : "flex-start"};
  padding: 5px;
  font-size: 12px;
`;
export const ChatStatus = styled.div<{
  direction: TDirection;
}>`
  align-self: ${(props) =>
    props.direction === "outbound" ? "flex-end" : props.direction === "note" ? "center" : "flex-start"};
  padding: 5px;
  font-size: 10px;
  color: #111;
`;

export const ChatTextWrap = styled.div<{
  direction: TDirection;
  hide?: boolean;
}>`
  align-self: ${(props) =>
    props.direction === "outbound" ? "flex-end" : props.direction === "note" ? "center" : "flex-start"};
  margin-bottom: 10px;
  display: ${(props) => (props.hide ? "none" : "flex")};
  flex-direction: column;
  word-wrap: break-word;
  white-space: pre-wrap;
  max-width: 100%;
`;
export const ChatModuleWrap = styled.div<{
  direction: TDirection;
}>`
  align-self: ${(props) =>
    props.direction === "outbound" ? "flex-end" : props.direction === "note" ? "center" : "flex-start"};
  margin-bottom: 10px;
`;
export const ChatModule = styled.div<{
  direction: TDirection;
  active: boolean;
}>`
  cursor: ${(props) => (props.active ? "pointer" : "auto")};
  border-radius: 5px;
  padding: 12px;
  background-color: ${(props) => (props.direction === "outbound" ? "#abe0f5" : "#eee")};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 12px;
  svg {
    width: 50px;
    height: 50px;
  }
`;
export const ChatText = styled.div<{
  direction: "outbound" | "inbound";
}>`
  border-radius: 12px;
  padding: 12px;
  margin: 3px;
  white-space: pre-wrap;
  background-color: ${(props) => (props.direction === "outbound" ? "#abe0f5" : "#eee")};
  word-wrap: break-word;
  white-space: pre-wrap;
  max-width: 100%;
  transition: background-color 1000ms linear;

  &.transcript-item strong::first-letter {
    text-transform: uppercase;
  }

  &.transcript-identity-responder {
    background-color: #abe0f5;
  }

  &.transcript-identity-0 {
    background-color: #abe0f5;
  }

  &.transcript-identity-1 {
    background-color: #eee;
  }

  &.transcript-identity-2 {
    background-color: #ffc107;
  }
  &.transcript-identity-3 {
    background-color: #0acc95;
  }
  &.transcript-identity--1 {
    background-color: #0acc95;
  }

  &.highlight {
    background-color: #d6d8d9;
  }
`;
export const ChatInputContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 8px;
  background-color: #eee;
  textarea {
    & :active,
    & :focus-within {
      border: 1px solid #7b7c7d;
    }
    & :focus-visible {
      outline: none;
    }
  }
`;

export const ChatInput = styled.textarea<{ recipient: boolean }>`
  width: ${(props) => (props.recipient ? "calc(100%)" : "calc(100% - 55px)")};
  margin-right: ${(props) => (props.recipient ? "5px" : 0)};
  border-radius: 4px;
  border-color: #c6c8ca;
`;

export const ChatButton = styled.button`
  width: 100px;
  background: #abe0f5;
  border: 0;
  cursor: pointer !important;
  height: 100%;
`;

export const ChatMenu = styled.div`
  width: 40px;
  border: 0;
  cursor: pointer;
`;

export const TemplateMenu = styled.div`
  width: 40px;
  border: 0;
  cursor: pointer;
`;

export const ChatToggle = styled.div`
  cursor: pointer;
  height: 100%;
  display: inline-flex;
  align-items: center;
  svg {
    width: 30px;
    height: 30px;
    margin-top: 5px;
    margin-left: 5px;
  }
`;

export const TemplateActionList = styled.div<{ show: boolean }>`
  display: ${(props) => (props.show ? "flex" : "none")};
  position: absolute;
  flex-direction: column;
  padding: 5px;
  border-radius: 5px;
  border: 1px solid #eee;
  width: 150px;
  background: white;
  bottom: 60px;
  right: 10px;
  max-height: 250px;
  overflow: auto;
  > div + div {
    margin-top: 5px;
    font-size: 16px;
  }
`;

export const ChatActionList = styled.div<{ show: boolean }>`
  display: ${(props) => (props.show ? "flex" : "none")};
  position: absolute;
  flex-direction: column;
  padding: 5px;
  border-radius: 5px;
  border: 1px solid #eee;
  width: 250px;
  width: 150px;
  background: white;
  bottom: 60px;
  left: 10px;
  max-height: 250px;
  overflow: auto;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08);
  > div + div {
    margin-top: 5px;
    font-size: 16px;
  }
`;
export const ChatMenuItem = styled.div`
  cursor: pointer;
  color: black;
  padding: 0.25rem 0.5rem;
  height: 25px;
  vertical-align: middle;
  display: inline-block;
  line-height: 25px;
  :hover {
    background: #e9ecef;
  }
`;

export const TranslateWrap = styled.div``;
export const TranslatedText = styled.div`
  background: white;
  padding: 8px;
`;
