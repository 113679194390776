import React, { FunctionComponent, useEffect, useState } from "react";
import { ConveyApi } from "@utils";
import { CircleButton } from "@components/styles";
import { Modal } from "@components/Modal";
import { SubmitButton, FormTextarea } from "@components/styles";
import { connect } from "react-redux";
import { IRootState } from "@utils";
import { Spinner } from "@components/Spinner";
// @ts-ignore
import Close from "@assets/icons/close.svg";

interface ICloseSMSProps {
  conversationId: string;
  onClose: () => void;
  agency: IRootState["agency"];
  app: IRootState["app"];
}

const XCloseSMS: FunctionComponent<ICloseSMSProps> = ({ agency, app, conversationId, onClose }: ICloseSMSProps) => {
  const [active, setActive] = useState(false);
  const [loading, setLoading] = useState(false);
  const [closeMessage, setCloseMessage] = useState(agency?.settings?.closedConversationMessage || "");

  const close = async () => {
    setLoading(true);
    const closeResponse = await ConveyApi.closeConversation({ id: conversationId, message: closeMessage }).catch(
      () => null
    );
    setLoading(false);
    if (closeResponse && closeResponse.status === "closed") {
      localStorage.removeItem("conversation");
      localStorage.removeItem("phoneNumber");
      onClose && onClose();
    }
  };

  useEffect(() => {
    if (active && !app.initOptions.options.showModal) {
      close();
    }
  }, [app.initOptions.options.showModal, active]);

  return (
    <>
      {active && app.initOptions.options.showModal && (
        <Modal
          show={active && app.initOptions.options.showModal}
          title="Close Conversation"
          onClose={() => {
            setActive(false);
          }}
          modalBody={
            <>
              <FormTextarea
                placeholder={agency?.settings?.closedConversationMessage}
                onChange={(e) => {
                  if (e.target.value === "") {
                    setCloseMessage(agency?.settings?.closedConversationMessage);
                  } else {
                    setCloseMessage(e.target.value);
                  }
                }}
                value={closeMessage === agency?.settings?.closedConversationMessage ? "" : closeMessage}
              />
              <SubmitButton
                data-type="CloseSMS"
                disabled={!closeMessage || loading}
                buttonType="danger"
                onClick={() => {
                  if (closeMessage) {
                    close();
                  }
                }}
              >
                Send & Close Conversation
                {loading && <Spinner />}
              </SubmitButton>
            </>
          }
        />
      )}
      <CircleButton
        data-type="CloseSMS"
        buttonType="danger"
        onClick={() => {
          setActive(true);
        }}
      >
        {loading ? <Spinner /> : <Close />}
      </CircleButton>
    </>
  );
};

const mapStateToProps = ({ agency, app }: IRootState) => ({
  agency,
  app
});

export const CloseSMS = connect(mapStateToProps, {})(XCloseSMS);
