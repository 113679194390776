import React, { FunctionComponent, useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { IRootState } from "@utils";
import { ChatModule, ChatModuleWrap } from "@components/chatStyles";
import { LocationModal } from "@components/LocationModal";
import { ItalicSpan, UnderlinedSpan } from "@components/styles";
import { TDirection } from "@lib/utils";
import moment from "moment-timezone";
// @ts-ignore
import MapMarker from "@assets/icons/mapMarker.svg";

interface ILocationModuleProps {
  conversationId: string;
  direction: string;
  nextNavShown: boolean;
  conversations: IRootState["conversations"];
  app: IRootState["app"];
  mapLoaded: () => void;
  messageId: string;
  locationIsFresh: boolean;
}

declare global {
  interface Window {
    PinnacleMap: any;
  }
}

interface ILatLng {
  lat: number;
  lng: number;
}

const XLocationModule: FunctionComponent<ILocationModuleProps> = ({
  conversationId,
  conversations,
  direction,
  nextNavShown,
  mapLoaded,
  messageId,
  locationIsFresh
}: ILocationModuleProps) => {
  const [active, setActive] = useState(false);
  const [showMap, setShowMap] = useState(0);
  const [center, setCenter] = useState<ILatLng | null>(null);

  const $mapId = `pinnacleMapContainer-${messageId}`;

  const currentConversation = conversations[conversationId];

  let lat = 0.0,
    lng = 0.0;

  if (currentConversation?.location) {
    const { lat: latC, lng: lngC } = currentConversation.location;
    lat = latC || 0.0;
    lng = lngC || 0.0;
  }

  useEffect(() => {
    if (lat && lng) {
      setCenter({ lat, lng });
    }
  }, [lat, lng]);

  const getLastLocationTime = (updatedAt) => {
    try {
      const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
      const formattedTime = moment.utc(updatedAt, "x").tz(tz).fromNow();
      return formattedTime;
    } catch (e) {
      return updatedAt;
    }
  };

  return (
    <>
      {center && locationIsFresh && lat && lng && (
        <>
          {!!showMap && (
            <LocationModal
              $mapId={$mapId}
              show={showMap}
              nextNavShown={nextNavShown}
              conversationId={conversationId}
              mapLoaded={mapLoaded}
              locationIsFresh={locationIsFresh}
            />
          )}
          <ChatModuleWrap direction={direction as TDirection}>
            <ChatModule
              active={!!(lat && lng)}
              direction={direction as TDirection}
              data-type="LocationModule"
              onClick={() => {
                setActive(true);
                setShowMap(new Date().getTime());
              }}
            >
              <MapMarker />
              <div style={{ marginTop: 12, textAlign: "center" }}>
                {<UnderlinedSpan>{currentConversation.location.normalizedAddress}</UnderlinedSpan>}
                {currentConversation.location.updatedAt && (
                  <>
                    <br />
                    <ItalicSpan>{getLastLocationTime(currentConversation.location.updatedAt)}</ItalicSpan>
                  </>
                )}
              </div>
            </ChatModule>
          </ChatModuleWrap>
        </>
      )}
    </>
  );
};

const mapStateToProps = ({ conversations, app }: IRootState) => ({
  conversations,
  app
});

export const LocationModule = connect(mapStateToProps, {})(XLocationModule);
