import React, { FunctionComponent, useEffect, useState, useRef } from "react";
import { ConveySocket, ConveyApi, ILang, CNeuralNames, CNueralDialectMap } from "@utils";
import { ChatStatus, ChatWrapContainer, ChatContainer, ChatText, ChatWrap } from "@components/chatStyles";
import { connect } from "react-redux";
import { IRootState } from "@utils";
import { formatPhoneNumber, formatSentence } from "@lib/utils/helpers";
import moment from "moment-timezone";
import { SentimentContainer, KeywordsContainer, Keyword, SentimentScore, KeywordTimestamp } from "./transcribeStyles";

interface ITranscribeChatProps {
  agencyId: string;
  correlationId: string;
  callerLanguage: string;
  talkback?: boolean;
  conversationId?: string;
  neuralName: string;
  app: IRootState["app"];
  callback?: (eventData: Record<any, any>) => void;
}

const XTranscribeChat: FunctionComponent = ({
  correlationId,
  callback,
  callerLanguage,
  app,
  conversationId
}: ITranscribeChatProps) => {
  const { agencyId } = app;
  const [_, setTranscriptions] = useState([]);
  const [foundKeywords, setFoundKeywords] = useState({});
  const foundKeywordsRef = useRef([]);
  const allKeywordsRef = useRef({});
  const transcriptsIdentitiesRef = useRef([]);
  const transcriptsRef = useRef([]);
  const [backgroundColor, setBackgroundColor] = useState("#fff");
  const [sentimentScore, setSentimentScore] = useState("");
  const currentLang = JSON.parse(localStorage.getItem("neuralSelection"));
  const [lang, setLang] = useState<ILang>(
    currentLang
      ? { outputLanguage: currentLang.outputLanguage, neuralName: currentLang.neuralName }
      : {
          outputLanguage: "en-US",
          neuralName: "en-US-JennyNeural"
        }
  );
  const scrollToBottom = () => {
    setTimeout(() => {
      const wrapperEle = document.getElementById(`transcription-container-${correlationId}`);
      if (wrapperEle) {
        wrapperEle.scrollTo(0, 100000);
      }
    }, 250);
  };

  const findKeywords = (data: Record<any, any>): string[][] => {
    const found = [];
    const dataRoot = data?.event ? data.event : data;
    return dataRoot.channel?.search
      ?.flatMap((searchItem) => {
        if (searchItem.hits.length > 0) {
          return searchItem.hits.map((hit) => {
            if (hit.confidence > 0.8) {
              if (!found.includes(searchItem.hits[0].snippet)) {
                found.push(searchItem.hits[0].snippet);
                return [searchItem.query, searchItem.hits[0].snippet];
              }
            }
          });
        } else {
          return null;
        }
      })
      .filter(Boolean);
  };

  useEffect(() => {
    if (CNueralDialectMap[callerLanguage]) {
      const nueralNameIndex = CNueralDialectMap[callerLanguage];
      const lang = CNeuralNames[nueralNameIndex];
      localStorage.setItem(
        "neuralSelection",
        JSON.stringify({
          language: nueralNameIndex,
          outputLanguage: lang.outputLanguage,
          neuralName: lang.neuralName
        })
      );
      setLang(lang);
    }
  }, [callerLanguage]);

  const getTimestamp = (updatedAt) => {
    try {
      const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
      const formattedTime = (updatedAt ? moment(updatedAt) : moment()).tz(tz).format("h:mm:ss a");
      return formattedTime;
    } catch (e) {
      return updatedAt;
    }
  };

  useEffect(() => {
    if (!conversationId) {
      return;
    }

    (async () => {
      const keywords = await ConveyApi.getKeywords();
      const keywordsObj = {};
      keywords.forEach((keyword) => {
        keywordsObj[keyword.term] = keyword;
      });
      allKeywordsRef.current = keywordsObj;
      const messagesResponse = await ConveyApi.getSourceMessages({ source: "transcribe", conversationId });
      transcriptsRef.current = [
        ...transcriptsRef.current,
        ...messagesResponse
          .filter((e) => e.source === "transcribe")
          .map((m) => {
            const formattedIdentity =
              m.direction === "outbound"
                ? app.agencyName
                : formatPhoneNumber(
                    m.recipient_label && m.recipient_label != "" ? m.recipient_label : m.recipient_number
                  );

            if (!transcriptsIdentitiesRef.current.includes(formattedIdentity)) {
              transcriptsIdentitiesRef.current.push(formattedIdentity);
            }

            let highlightWords = [];
            let parsedData = null;
            if (m.metadata) {
              try {
                parsedData = JSON.parse(m.metadata);
                if (parsedData.provider_data) {
                  if (parsedData.provider_data?.provider === "deepgram" && parsedData.provider_data?.data) {
                    highlightWords = findKeywords(parsedData.provider_data?.data);
                  }
                }
              } catch (error) {
                console.log({ error });
                // Handle the error here
              }
            }

            let body = m.body;

            if (highlightWords && highlightWords.length > 0) {
              highlightWords.forEach((words) => {
                if (words[1] && body.toLowerCase().includes(words[1])) {
                  const regEx = new RegExp(words[1], "g");
                  body = formatSentence(
                    body.toLowerCase().replace(regEx, '<strong style="color: red">' + words[1] + "</strong>")
                  );
                  foundKeywordsRef.current.push({
                    term: words[0],
                    data: keywordsObj[words[0]],
                    id: m.message_id,
                    created_at: m.created_at
                  });
                }
              });
            }
            setFoundKeywords(foundKeywordsRef.current);
            return {
              id: m.message_id,
              identity: formattedIdentity,
              dialogue: body,
              created_at: m.created_at,
              line: [formattedIdentity, body].join(": ")
            };
          })
      ];
      setTranscriptions(transcriptsRef.current);
      setTimeout(() => {
        scrollToBottom();
      }, 500);
    })();
  }, [conversationId]);

  useEffect(() => {
    ConveySocket.initSip({
      agencyId,
      correlationId
    })
      .listen(
        ConveySocket.getSipSentiment({
          agencyId,
          correlationId
        }),
        (data) => {
          if (data.hexColor) {
            setBackgroundColor(`#${data.hexColor}`);
          }
          if (data.providerData?.data?.score) {
            setSentimentScore(data.providerData?.data?.score);
          }
        }
      )
      .listen(
        ConveySocket.getSipRoom({
          agencyId,
          correlationId
        }),
        (data) => {
          const { direction, status, transcription, translations, identity, languages, id } = data;

          callback && callback(data);

          let highlightWords = [];

          if (data.providerData) {
            if (data.providerData?.provider === "deepgram") {
              highlightWords = findKeywords(data.providerData?.data);
            }
          }

          const currentLength = transcriptsRef.current.length;
          let dialogue;
          if (languages && languages.to.substring(0, 2) == "en")
            dialogue = translations.to ? translations.to : transcription;

          const formattedIdentity = formatPhoneNumber(data.identity);

          if (!dialogue || dialogue == "") {
            return;
          }

          if (highlightWords && highlightWords.length > 0) {
            highlightWords.forEach((words) => {
              if (words[1] && dialogue.toLowerCase().includes(words[1])) {
                const regEx = new RegExp(words[1], "g");
                dialogue = formatSentence(
                  dialogue.toLowerCase().replace(regEx, '<strong style="color: red">' + words[1] + "</strong>")
                );
                foundKeywordsRef.current.push({
                  term: words[0],
                  data: allKeywordsRef.current[words[0]],
                  id,
                  created_at: data.createdAt
                });
              }
            });
          }
          setFoundKeywords(foundKeywordsRef.current);
          const line = `${formattedIdentity}: ${dialogue}`;

          if (!transcriptsIdentitiesRef.current.includes(formattedIdentity)) {
            transcriptsIdentitiesRef.current.push(formattedIdentity);
          }

          if (currentLength > 0) {
            const lastTranscription = transcriptsRef.current[currentLength - 1];

            if (
              identity == lastTranscription.identity &&
              dialogue.toLowerCase() == lastTranscription.dialogue.toLowerCase()
            ) {
              return;
            }

            if (lastTranscription.status == "recognized") {
              transcriptsRef.current = [
                ...transcriptsRef.current,
                {
                  line,
                  dialogue,
                  identity: formattedIdentity,
                  transcription,
                  status,
                  direction,
                  id,
                  created_at: data.createdAt
                }
              ];
            } else {
              if (identity != lastTranscription.identity) {
                transcriptsRef.current = [
                  ...transcriptsRef.current,
                  {
                    line,
                    dialogue,
                    identity: formattedIdentity,
                    transcription,
                    status,
                    direction,
                    id,
                    created_at: data.createdAt
                  }
                ];
              } else {
                transcriptsRef.current.pop();
                transcriptsRef.current = [
                  ...transcriptsRef.current,
                  {
                    line,
                    dialogue,
                    identity: formattedIdentity,
                    transcription,
                    status,
                    direction,
                    id,
                    created_at: data.createdAt
                  }
                ];
              }
            }
          } else {
            transcriptsRef.current = [
              ...transcriptsRef.current,
              {
                line,
                dialogue,
                identity: formattedIdentity,
                transcription,
                status,
                direction,
                id,
                created_at: data.createdAt
              }
            ];
          }
          setTranscriptions(transcriptsRef.current);
          setTimeout(() => {
            scrollToBottom();
          }, 250);
        },
        true
      );
  }, []);

  return (
    <ChatWrap style={{ flexDirection: "row", height: "100%" }}>
      {app.initOptions.options.enableSentimentAnalysis && (
        <SentimentContainer style={{ backgroundColor }}>
          {sentimentScore && <SentimentScore>{sentimentScore}</SentimentScore>}
        </SentimentContainer>
      )}
      <ChatWrapContainer>
        <ChatContainer id={`transcription-container-${correlationId}`}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "start"
            }}
          >
            {transcriptsRef.current.map((transcript) => {
              return (
                <>
                  <ChatText
                    key={transcript.id}
                    id={`transcribe-msg-${transcript.id}`}
                    direction={transcript.direction}
                    className={`transcript-item transcript-identity-${
                      transcript.identity !== app.agencyName
                        ? transcriptsIdentitiesRef.current.indexOf(transcript.identity)
                        : "responder"
                    }`}
                    dangerouslySetInnerHTML={{ __html: transcript.line }}
                  />
                  {
                    <ChatStatus style={{ marginBottom: 10 }} direction={transcript.direction}>
                      {getTimestamp(transcript.created_at)}
                    </ChatStatus>
                  }
                </>
              );
            })}
          </div>
        </ChatContainer>
      </ChatWrapContainer>
      <KeywordsContainer>
        {foundKeywordsRef.current.map((keyword) => {
          return (
            <Keyword
              key={[keyword.id, keyword.term].join("")}
              level={keyword.data ? keyword.data.level : 1}
              onClick={() => {
                const chatMsg = document.getElementById(`transcribe-msg-${keyword.id}`);
                if (chatMsg) {
                  chatMsg.scrollIntoView({ behavior: "smooth", block: "nearest", inline: "start" });
                  chatMsg.classList.add("highlight");
                  setTimeout(() => {
                    chatMsg.classList.remove("highlight");
                  }, 1_000);
                }
              }}
            >
              {keyword.term}
              <KeywordTimestamp>{getTimestamp(keyword.created_at)}</KeywordTimestamp>
            </Keyword>
          );
        })}
      </KeywordsContainer>
    </ChatWrap>
  );
};

const mapStateToProps = ({ app }: IRootState) => ({
  app
});

export const TranscribeChat = connect(mapStateToProps, {})(XTranscribeChat);
