import React, { FunctionComponent, useEffect, useState } from "react";
import { connect } from "react-redux";
import { IRootState } from "@utils";
import { ConveyApi } from "@utils";
import { SubmitButton, Textarea, Alert, SwitchWrap, SwitchItem, CircleButton } from "@components/styles";
import { Modal } from "@components/Modal";
import Switch from "react-switch";
import { setConversationVideo, setVideoRoomCount, setVideoShow } from "@actions";
import { Spinner } from "@components/Spinner";
// @ts-ignore
import Video from "@assets/icons/video.svg";

interface XRequestVideoProps {
  conversationId: string;
  recipientId: string;
  agencyId: string;
  phoneNumber: string;
  agency: IRootState["agency"];
  app: IRootState["app"];
  setConversationVideo: typeof setConversationVideo;
  setVideoRoomCount: typeof setVideoRoomCount;
  setVideoShow: typeof setVideoShow;
  onComplete?: (roomId?: string) => void;
}

const XRequestVideo: FunctionComponent<XRequestVideoProps> = ({
  conversationId,
  recipientId,
  setConversationVideo,
  setVideoRoomCount,
  setVideoShow,
  phoneNumber,
  agency,
  app,
  onComplete
}: XRequestVideoProps) => {
  const [active, setActive] = useState(false);
  const [loading, setLoading] = useState(false);
  const [requestMessage, setRequestMessage] = useState(agency?.settings?.videoRequestExplainMsg || "");
  const [success, setSuccess] = useState("");
  const [videoEnabled, setVideoEnabled] = useState(false);
  const [videoActive, setVideoActive] = useState(false);
  const [audioEnabled, setAudioEnabled] = useState(false);
  const [blurEnabled, setBlurEnabled] = useState(true);
  const [recordEnabled, setRecordEnabled] = useState(true);
  const [transcribeEnabled, setTranscribeEnabled] = useState(true);

  useEffect(() => {
    (async () => {
      const currentRooms = await ConveyApi.getVideoRooms({
        conversationId: conversationId,
        status: "active"
      });
      setVideoRoomCount(currentRooms.length);
    })();
  }, [conversationId]);

  const request = async () => {
    setLoading(true);
    const currentRooms = await ConveyApi.getVideoRooms({
      conversationId,
      status: "active"
    });
    if (currentRooms.length === 0) {
      const videoRoomResponse = await ConveyApi.createVideoRoom({
        recipientId: recipientId,
        conversationId,
        transcribe: transcribeEnabled,
        record: recordEnabled,
        blur: blurEnabled,
        enableAudio: audioEnabled,
        enableVideo: videoEnabled,
        emptyRoomTimeout: agency?.settings.videoRequestTimeout
      });
      const videoRoomId = videoRoomResponse.room_id;
      await ConveyApi.createVideoTokens({
        recipientId,
        videoRoomId,
        phoneNumber,
        conversationId,
        invitationMessage: requestMessage,
        enableTranscription: transcribeEnabled,
        performVideoRecord: recordEnabled,
        blur: blurEnabled,
        enableAudio: audioEnabled,
        enableVideo: videoEnabled
      });
      const createVideoRoomTokenResponse = await ConveyApi.createVideoRoomToken({
        videoRoomId,
        participant: app.agencyName
      });
      const videoRoomToken = createVideoRoomTokenResponse.data.access_token;
      const videoRoomResourceId = createVideoRoomTokenResponse.data.resource_id;

      setConversationVideo &&
        setConversationVideo(conversationId, {
          videoEnabled,
          audioEnabled,
          transcribeEnabled,
          blurEnabled,
          recordEnabled,
          videoRoomId,
          videoRoomResourceId,
          videoRoomToken,
          invitationMessage: requestMessage
        });
      setVideoRoomCount(app.videoRoomCount + 1);
      onComplete && onComplete(videoRoomId);
    } else {
      onComplete && onComplete(currentRooms[0].room_id);
    }
    setLoading(false);
    setSuccess("Request Sent");
    setTimeout(() => {
      setActive(false);
      setVideoActive(true);
    }, 1 * 1000);
  };

  useEffect(() => {
    if (active) {
      setSuccess("");
    }
  }, [active]);

  useEffect(() => {
    if (active && !app.initOptions.options.showModal) {
      request();
    }
  }, [app.initOptions.options.showModal, active]);

  return (
    <>
      {active && app.initOptions.options.showModal && (
        <Modal
          show={active && app.initOptions.options.showModal}
          title="Video Request"
          onClose={() => {
            setActive(false);
          }}
          modalBody={
            success ? (
              <Alert alertType="success">{success}</Alert>
            ) : (
              <>
                <Textarea
                  placeholder={agency?.settings?.videoRequestExplainMsg}
                  onChange={(e) => {
                    if (e.target.value === "") {
                      setRequestMessage(agency?.settings?.videoRequestExplainMsg);
                    } else {
                      setRequestMessage(e.target.value);
                    }
                  }}
                  value={requestMessage === agency?.settings?.videoRequestExplainMsg ? "" : requestMessage}
                  autoFocus={true}
                />
                <SwitchWrap>
                  {agency.settings.videoEnabled && agency.settings["video:enableAgentVideo"] && (
                    <SwitchItem>
                      <Switch
                        onChange={(checked) => {
                          setVideoEnabled(checked);
                        }}
                        checked={videoEnabled}
                        uncheckedIcon={false}
                        checkedIcon={false}
                      />
                      Enable my video
                    </SwitchItem>
                  )}
                  <SwitchItem>
                    <Switch
                      onChange={(checked) => {
                        setAudioEnabled(checked);
                      }}
                      checked={audioEnabled}
                      uncheckedIcon={false}
                      checkedIcon={false}
                    />
                    Enable my audio
                  </SwitchItem>
                  <SwitchItem>
                    <Switch
                      onChange={(checked) => {
                        setBlurEnabled(checked);
                      }}
                      checked={blurEnabled}
                      uncheckedIcon={false}
                      checkedIcon={false}
                    />
                    Blur recipient video
                  </SwitchItem>
                  <SwitchItem>
                    <Switch
                      onChange={(checked) => {
                        setRecordEnabled(checked);
                      }}
                      checked={recordEnabled}
                      uncheckedIcon={false}
                      checkedIcon={false}
                    />
                    Record session
                  </SwitchItem>
                  {!app.initOptions.options.disableVideoTranscribe && (
                    <SwitchItem>
                      <Switch
                        onChange={(checked) => {
                          setTranscribeEnabled(checked);
                        }}
                        checked={transcribeEnabled}
                        uncheckedIcon={false}
                        checkedIcon={false}
                      />
                      Enable transcription
                    </SwitchItem>
                  )}
                </SwitchWrap>
                <SubmitButton
                  data-type="RequestLocation"
                  disabled={!requestMessage || loading}
                  buttonType="info"
                  onClick={() => {
                    if (requestMessage) {
                      request();
                    }
                  }}
                >
                  Send Video Request
                  {loading && <Spinner />}
                </SubmitButton>
              </>
            )
          }
        />
      )}
      <CircleButton
        buttonType="primary"
        data-type="RequestVideo"
        onClick={() => {
          if (app.videoRoomCount > 0) {
            setVideoShow();
            setVideoActive(true);
          } else {
            setActive(true);
          }
        }}
      >
        {loading ? <Spinner /> : <Video />}
      </CircleButton>
    </>
  );
};

const mapStateToProps = ({ agency, app }: IRootState) => ({
  agency,
  app
});

export const RequestVideo = connect(mapStateToProps, { setConversationVideo, setVideoRoomCount, setVideoShow })(
  XRequestVideo
);
