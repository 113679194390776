import React, { FunctionComponent, useEffect, useState } from "react";
import Dropdown from "rc-dropdown";
import Menu, { Item as MenuItem } from 'rc-menu';
import 'rc-dropdown/assets/index.css';
import { connect } from "react-redux";
import { IRootState } from "@utils";
import { delay } from "@utils/helpers";
import {
  VideoCallModalHeader,
  VideoCallModalTitle,
  VideoCallModalActionsWrapper,
  VideoCallModalBody,
  VideoCallModalActionButton,
  VideoCallModalDefaultStyle,
  VideoCallModalFullScreenStyle,
  VideoCallModalViewOption
} from "./videoCallModalStyles";
import {
  setVideoShow,
  setVideoNoShow,
  setVideoRoomMaximized,
  setVideoRoomMinimized,
  setRoomJoinedStatus
} from "@actions";
import { SDK } from "@lib/index";
import ReactModal from "react-modal";
import Draggable from "react-draggable";
// @ts-ignore
import ModalViewOptions from "@assets/icons/grip.svg";
// @ts-ignore
import ModalClose from "@assets/icons/modalClose.svg";
// @ts-ignore
import ModalShare from "@assets/icons/modalShare.svg";

interface IVideoCallModalProps {
  conversationId: string;
  phoneNumber: string;
  setVideoNoShow: typeof setVideoNoShow;
  setVideoShow: typeof setVideoShow;
  setVideoRoomMaximized: typeof setVideoRoomMaximized;
  setVideoRoomMinimized: typeof setVideoRoomMinimized;
  setRoomJoinedStatus: typeof setRoomJoinedStatus;
  app: IRootState["app"];
  agency: IRootState["agency"];
  show: boolean;
  $videoId: string;
  conversations: IRootState["conversations"];
}

const XVideoCallModal: FunctionComponent<IVideoCallModalProps> = ({
  app,
  conversations,
  conversationId,
  phoneNumber,
  agency,
  setVideoNoShow,
  setVideoRoomMaximized,
  setVideoRoomMinimized,
  setRoomJoinedStatus,
  show,
  $videoId
}: IVideoCallModalProps) => {
  const [fullScreen, setFullScreen] = useState(false);
  const currentConversation = conversations[conversationId];

  const openShareModal = () => {
    if (app?.activeRoom?.conversation_id) {
      const shareButton = document.getElementById(`OpenVideoShare-${app.activeRoom.conversation_id}`);
      shareButton && shareButton.click();
    }
  }

  const onClose = () => {
    // @ts-ignore
    window.ConveyCurrentVideoRooms &&
      // @ts-ignore
      window.ConveyCurrentVideoRooms[conversationId] &&
      // @ts-ignore
      window.ConveyCurrentVideoRooms[conversationId].execLeaveRoom &&
      // @ts-ignore
      window.ConveyCurrentVideoRooms[conversationId].execLeaveRoom(conversationId);
    if (window.SDK.instances[phoneNumber].conveyVideo) {
      window.SDK.instances[phoneNumber].conveyVideo.destroy();
    }
    setVideoNoShow && setVideoNoShow();
    setRoomJoinedStatus(false);
  }

  useEffect(() => {
    if (show) {
      let transcribeEnabled = true;
      let recordEnabled = true;
      let blurEnabled = true;
      let audioEnabled = false;
      let videoEnabled = false;
      let invitationMessage = agency?.settings?.videoRequestExplainMsg || "";
      if (currentConversation.video) {
        const {
          invitationMessage: cInvitationMessage,
          transcribeEnabled: cTranscribeEnabled,
          recordEnabled: cRecordEnabled,
          videoEnabled: cVideoEnabled,
          audioEnabled: cAudioEnabled,
          blurEnabled: cBlurEnabled
        } = currentConversation.video;
        invitationMessage = cInvitationMessage;
        transcribeEnabled = cTranscribeEnabled;
        recordEnabled = cRecordEnabled;
        blurEnabled = cBlurEnabled;
        audioEnabled = cAudioEnabled;
        videoEnabled = cVideoEnabled;
      }
      (async () => {
        await delay(100);
        SDK.init({
          agencyId: app.agencyId,
          agencySecret: app.agencySecret,
          phoneNumber,
          options: app.initOptions.options
        }).videoCall({
          $container: $videoId,
          conversationId,
          invitationMessage,
          transcribe: transcribeEnabled,
          record: recordEnabled,
          blur: blurEnabled,
          enableAudio: audioEnabled,
          enableVideo: videoEnabled
        });
      })();
    }
  }, [show]);

  const modalViewOptions = (
    <Menu>
      {!fullScreen && (
        <MenuItem
          key={`${$videoId}-EnterFullscreen`}
          onClick={() => setFullScreen(true)}
        >
          <VideoCallModalViewOption>
            Enter Fullscreen
          </VideoCallModalViewOption>
        </MenuItem>
      )}
      {fullScreen && (
        <MenuItem
          key={`${$videoId}-ExitFullscreen`}
          onClick={() => setFullScreen(false)}
        >
          <VideoCallModalViewOption>
            Exit Fullscreen
          </VideoCallModalViewOption>
        </MenuItem>
      )}
      {app.videoRoomMinimized && (
        <MenuItem
          key={`${$videoId}-Maximize`}
          onClick={() => {
            setVideoRoomMaximized && setVideoRoomMaximized();
          }}
        >
          <VideoCallModalViewOption>
            Maximize
          </VideoCallModalViewOption>
        </MenuItem>
      )}
      {!app.videoRoomMinimized && (
        <MenuItem
          key={`${$videoId}-Minimize`}
          onClick={() => {
            setVideoRoomMinimized && setVideoRoomMinimized();
          }}
        >
          <VideoCallModalViewOption>
            Minimize
          </VideoCallModalViewOption>
        </MenuItem>
      )}
    </Menu>
  );

  return (
    <ReactModal
      isOpen={show}
      ariaHideApp={false}
      style={fullScreen ? VideoCallModalFullScreenStyle : VideoCallModalDefaultStyle}
      onRequestClose={() => {
        onClose();
      }}
      shouldCloseOnOverlayClick={false}
      overlayElement={(props, contentElement) => {
        return (
          <div {...props}>
            <Draggable
              cancel=".ConveyEmbed--MapWrap, .ConveyEmbed--Input, .ConveyEmbed--Textarea, .ConveyEmbed--Map3DContainer"
              positionOffset={{ x: "-50%", y: "-50%" }}
            >
              {contentElement}
            </Draggable>
          </div>
        );
      }}
      portalClassName={$videoId}
    >
      <VideoCallModalHeader>
        <VideoCallModalTitle>Video Call</VideoCallModalTitle>
        <VideoCallModalActionsWrapper>
          <Dropdown
            trigger={["click"]}
            overlay={modalViewOptions}
          >
            <VideoCallModalActionButton
              data-type="Video-Call-Modal-View-Options"
              size={16}
            >
              <ModalViewOptions />
            </VideoCallModalActionButton>
          </Dropdown>
          <VideoCallModalActionButton
            data-type="Video-Call-Modal-Video-Room-Share"
            size={16}
            onClick={() => openShareModal()}
          >
            <ModalShare />
          </VideoCallModalActionButton>
          <VideoCallModalActionButton
            data-type="Video-Call-Modal-Close"
            size={16}
            onClick={() => {
              onClose();
            }}
          >
            <ModalClose />
          </VideoCallModalActionButton>
        </VideoCallModalActionsWrapper>
      </VideoCallModalHeader>
      <VideoCallModalBody>
        <div id={$videoId} />
      </VideoCallModalBody>
    </ReactModal>
  );
};

const mapStateToProps = ({ agency, app, conversations }: IRootState) => ({
  conversations,
  agency,
  app
});

export const VideoCallModal = connect(mapStateToProps, {
  setVideoShow,
  setVideoNoShow,
  setVideoRoomMinimized,
  setVideoRoomMaximized,
  setRoomJoinedStatus
})(XVideoCallModal);
