import { TContainerExperience } from "./reducerTypes";

export const formatPhoneNumber = (str) => {
  const cleaned = ("" + str).replace(/\D/g, "");

  const match = cleaned.match(/^(\d{1})(\d{3})(\d{3})(\d{4})$/);

  if (match) {
    return `(${match[2]}) ${match[3]}-${match[4]}`;
  }

  return str;
};

export const formatSentence = (text: string): string => {
  const re = /([!?.]\s+)([a-z])/g;
  return text.replace(re, (m, $1, $2) => $1 + $2.toUpperCase());
};

export const extractStyles = ($element) => {
  const dummy = document.createElement($element.nodeName);
  document.body.appendChild(dummy);

  let s = "";
  // @ts-ignore
  window.ConveyDefaultStyles ||= getComputedStyle(dummy);
  // @ts-ignore
  const defaultStyles = window.ConveyDefaultStyles;
  const elementStyles = getComputedStyle($element);

  for (const key in elementStyles) {
    // @ts-ignore
    const defaultKey = key.replace(/-([a-z])/g, function (g) {
      return g[1].toUpperCase();
    });
    if (elementStyles[key] && defaultStyles[defaultKey] !== elementStyles[key]) {
      const styleKey = key.replace(/[A-Z]/g, (match, offset) => (offset > 0 ? "-" : "") + match.toLowerCase());
      if (elementStyles.getPropertyValue(styleKey)) {
        s += styleKey + ":" + elementStyles.getPropertyValue(styleKey) + ";";
      }
    }
  }

  return s;
};

export const delay = (ms) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

export const appendAsChild = (id, $element, container, experience: TContainerExperience) => {
  const fullId = `${container}-${(id || "").replace(/\D/g, "")}-${experience}`;
  const $existingElement = document.getElementById(fullId);
  const styles = extractStyles(document.getElementById(container));

  if ($existingElement) {
    $existingElement.style.cssText = styles;

    return {
      fullId,
      $newElement: $existingElement
    };
  } else {
    const $newElement = document.createElement("div");
    $newElement.style.cssText = styles;
    $newElement.setAttribute("id", fullId);
    document.getElementById(container).appendChild($newElement);

    return {
      fullId,
      $newElement
    };
  }
};
